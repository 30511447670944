import styled from "styled-components"

const Sibarstyle = styled.div`
  display: flex;
  flex-direction: row;
  .sidebar {
    width: 100px;
    height: calc(100vh - 167px);
    overflow: auto;
    background: ${(props) => props.theme.bgcolorskyblue};
    color: ${(props) => props.theme.textcolordwhite3};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1;
    padding: 0 0px 10px;
    border-right: 1px solid ${(props) => props.theme.colorbluegreylight};
    .ant-badge {
      color: ${(props) => props.theme.textcolordwhite3};
    }
    .linkstyle {
      color: ${(props) => props.theme.textcolordwhite3};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px 0;
      height: auto;
      position: relative;
      margin: 0;
      font-size: 12px;
      &:hover,
      &.active {
        text-decoration: none;
        background: ${(props) => props.theme.bgcolordskyblue};

        &::after {
          height: 100%;
        }
      }
      &:focus {
        outline: 1px solid ${(props) => props.theme.colorlightblack};
      }
      span {
        margin: 0;
        font-size: 12px;
        &.anticon {
          font-size: 24px;
          margin-bottom: 8px;
        }
      }
    }
    .target-style {
      color: ${(props) => props.theme.textcolordwhite3};
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;
      padding: 8px 0 !important;
      height: auto !important;
      position: relative !important;
      margin: 0 !important;
      font-size: 12px !important;
      &:hover,
      &.active {
        text-decoration: none;
        background: ${(props) => props.theme.bgcolordskyblue};
        border-radius: 0 !important;
        color: ${(props) => props.theme.textcolordwhite3};

        &::after {
          height: 100%;
        }
      }
      &:focus {
        outline: 1px solid ${(props) => props.theme.colorlightblack};
      }
      span {
        margin: 0;
        font-size: 12px;
        &.anticon {
          font-size: 24px;
          margin-bottom: 8px;
        }
      }
    }
  }
  .meeting-subsidebar {
    width: 211px;
    background: ${(props) => props.theme.colordwhitedark};
    color: ${(props) => props.theme.textcolordwhite3};
    padding: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    transform: translateX(-100%);
    transition: 0.2s ease all;
    height: calc(100vh - 84px);
    overflow: auto;
    &.active {
      transform: translateX(0);
      visibility: visible;
      opacity: 1;
      position: relative;
    }
    ul {
      list-style: none;
      border-bottom: 1px solid ${(props) => props.theme.colorbluegreylight};
      margin-bottom: 25px;
      padding-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
      }
      .ant-typography {
        font-size: 14px;
        margin-bottom: 25px;
        padding: 0 20px;
        position: relative;

        .anticon {
          font-size: 20px;
          position: absolute;
          left: -10px;
          color: ${(props) => props.theme.textcolordarkgrey};
        }
      }
      .linkstyle {
        font-size: 12px;
        color: ${(props) => props.theme.textcolordblack};
        margin-bottom: 20px;
        padding: 0 20px;
        white-space: nowrap;
        position: relative;
        .badge {
          background-color: ${(props) => props.theme.colordanger};
          color: ${(props) => props.theme.textcolordwhite3};
          font-size: 8px;
          border-radius: 3px;
          padding: 3px;
          position: absolute;
          top: -7px;
          right: 71px;
        }
        &:hover,
        &.active {
          text-decoration: none;
          color: ${(props) => props.theme.primary};
        }
        span {
          display: none;
        }
      }
    }
  }
  .chat-subsidebar {
    width: 211px;
    background: ${(props) => props.theme.colordwhitedark};
    color: ${(props) => props.theme.textcolordwhite3};
    padding: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    transform: translateX(-100%);
    transition: 0.2s ease all;
    height: calc(100vh - 84px);
    overflow: auto;
    &.active {
      transform: translateX(0);
      visibility: visible;
      opacity: 1;
      position: relative;
    }
    .pinned-chat-channel {
      ul {
        max-height: 218px;
      }
    }
    ul {
      list-style: none;
      border-bottom: 1px solid ${(props) => props.theme.colorbluegreylight};
      margin-bottom: 25px;
      padding-bottom: 5px;
      max-height: 370px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0px;
      }
      &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
      }
      .ant-typography {
        font-size: 14px;
        margin-bottom: 25px;
        padding: 0 20px;
        position: relative;
        .anticon {
          font-size: 20px;
          position: absolute;
          left: -10px;
          color: ${(props) => props.theme.textcolordarkgrey};
        }
      }
      .linkstyle {
        font-size: 12px;
        color: ${(props) => props.theme.textcolordblack};
        margin-bottom: 20px;
        padding: 0 14px;
        white-space: nowrap;
        position: relative;
        .badge-wrapper {
          position: relative;
          .presence-room {
            position: absolute;
            bottom: -5px;
            right: -2px;
            .anticon {
              font-size: 12px !important;
              background: ${(props) => props.theme.colordwhitedark};
              color: ${(props) => props.theme.colordwhitedark};
              border-radius: 100%;
            }
          }
        }
        .profile-pic {
          width: 24px;
          min-width: 24px;
          height: 24px;
          border-radius: 100%;
          background: ${(props) => props.theme.primary};
          font-size: 12px;
          color: ${(props) => props.theme.textcolordwhite3};
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          font-size: 6px;

          .ant-avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border: none;
          }
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          &.two {
            span {
              width: 50%;
              display: flex;
              align-items: center;
              height: 100%;
              justify-content: center;
            }
          }
          &.three {
            > div {
              width: 50%;
              display: flex;
              align-items: center;
              height: 100%;
              justify-content: center;
              &.part {
                display: flex;
                flex-direction: column;
                span {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  height: 50%;
                  justify-content: center;
                }
              }
            }
          }
        }
        &:hover,
        &.active {
          text-decoration: none;
          color: ${(props) => props.theme.primary};
        }
        span {
          font-size: 10px;
        }
      }
    }
  }
`
export default Sibarstyle

export const PopoverStyles = styled.div`
  .popover-sidebar {
    width: 300px !important;

    .sidebar-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 12px;
      text-align: center;
      margin: 10px 0px;
      cursor: pointer;

      .icon-wrapper {
        width: 40px;
        height: 40px;
        border: 1px solid ${(props) => props.theme.bordercolorsharpgrey};
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 8px;
      }
      &:hover {
        .icon-wrapper {
          border-color: ${(props) => props.theme.primary};
        }
      }
    }
  }
`