export interface PartnerOnboardingProps {
  onFinish: () => void
  onNext: (formValues: any) => Promise<void>
  onBack: () => void
  onFinishLater: () => void
  partnerOnboardingDetails: any
  showComplete: boolean
  loadingAddPartnerOnboarding: boolean
  updatePartnerOnboardingDetails: (
    newDetails: Partial<PersonalDetails | BusinessDetails>,
    type: UpdateType
  ) => void
}

export type StepKeys =
  | "Personal Profile"
  | "Business Profile"
  | "NDA"
  | "Website Setup"
  | "W9 Form"
  | "Partner Agreement"
  | "Finished"

export interface PartnerOnboarding {
  partner_status: "onboarding" | string
  onboarding_completed_step: number
  onboarding_completed_date: string
}

export type UpdateType =
  | "personal_details"
  | "business_details"
  | "partner_onboarding"

export interface BusinessDetails {
  account_name: string
  phone: string
  website: string
  fax: string
  country: string
  state: string
  city: string
  street: string
  zip_code: string
}

export interface PersonalDetails {
  first_name: string
  last_name: string
  title: string
  email: string
  phone: string
  mobile: string
  fax: string
  mailing_country: string
  mailing_state: string
  mailing_city: string
  mailing_street: string
  mailing_zip: string
}

export interface OnboardingData {
  data: {
    partner_onboarding: PartnerOnboarding
    business_details: BusinessDetails
    personal_details: PersonalDetails
  }
}

export enum PartnerOnboardingRole {
  Onboard = "Onboard",
  OnboardTSD = "Onboard TSD"
}

export enum PartnerStatus {
  Onboarding = "onboarding",
  OnboardingComplete = "onboarding_complete"
}
