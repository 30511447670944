import React from "react"

function AlertIcon() {
  return (
    <span
      className="anticon anticon-alert"
      style={{ position: "relative", display: "inline-block" }}
    >
      <svg
        fill="currentcolor"
        width="24"
        height="24"
        viewBox="0 0 600 600"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M300.3,4.9C140.1,4.9,10.2,134.8,10.2,295c0,69.2,24.2,132.7,64.7,182.5V169.1c0-33.9,27.1-61.4,60.3-61.4h327.5c33.3,0,60.3,27.6,60.3,61.4v252.2c0,33.9-27.1,61.4-60.3,61.4H184.3L135,533.4c46.9,32.6,103.9,51.8,165.4,51.8c160.2,0,290.1-129.9,290.1-290.1C590.5,134.8,460.6,4.9,300.3,4.9z" />
        <path d="M402.1,443.9c-14.2-13.2-30.8-28.7-43.8-40.7c6.1-10.1,12.3-20.4,18.4-30.7c13.3,12.5,56.6,53.4,75.9,71.5h10.2c11.9,0,21.5-10.1,21.5-22.6V169.1c0-12.5-9.7-22.6-21.5-22.6h-1.6c-20.4,18.3-71,60.7-84.5,73.2c-6.1-10.1-12.3-20.4-18.4-30.5c13.2-12.3,31.5-29.2,46.1-42.7H135.2c-11.9,0-21.5,10.2-21.5,22.6v330.5l54.3-55.7H402.1z M389,277h61.4v38.2H389V277z M327.6,232.1c14.1,15.7,23,38.6,23,63.9c0,25.4-8.9,48.3-23,63.8V232.1z M143.5,315.2V277c0-21,13.8-38.2,30.7-38.2h61.4l76.7-57.3v229.1L235.6,354l-5.2,0l-40.9-0.6h-15.3C157.3,353.3,143.5,336.2,143.5,315.2z" />
        <path d="M94.3,547.3v-48.1c-6.8-6.9-13.3-14.1-19.4-21.7v117.5l60.1-61.7c-5.4-3.7-10.6-7.6-15.7-11.7L94.3,547.3z" />
        <path d="M119.3,521.7c-8.8-7-17.1-14.5-25-22.4v48.1L119.3,521.7z" />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-box-arrow-in-up-right"
        viewBox="0 0 16 16"
        style={{ position: "absolute", top: "-10px", right: "-10px" }}
      >
        <path
          fillRule="evenodd"
          d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5"
        />
        <path
          fillRule="evenodd"
          d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z"
        />
      </svg>
    </span>
  )
}

export default AlertIcon
