import { useMemo } from "react";

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { EmergencyAddressType } from "../../components/Modal/ResellerCustomerSitesModal";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import {
  accountZohoGet,
  createCustomerResellerUcaasSitePost,
  customerAdd,
  customerEdit,
  customerListGet,
  customerSummaryGet,
  customerusersListGet,
  domainValidatePost,
  extensionValidatePost,
  getCustomerResellerUcaasSiteAddressListGet,
  getCustomerResellerUcaasSiteListGet,
  getCustomerResellerUcaasSiteSingleAddressGet,
  inventoryListExport,
  resellerCustomerUserPost,
  resellerCustomerUserPut,
  updatecontactDirectory,
  updateCustomerResellerUcaasSitePut,
  userPermissionsGet,
  validateCustomerResellerUcaasSiteAddressDetailsPost,
} from "./CustomerService";

interface InitialState {
  customerData: any;
  customerusersData: any;
  isValidDomain: boolean | undefined;
  extensionMessage: string;
  domainMessage: string;
  zohoDetails: {
    id: string;
    Account_Name: string;
    Email_Domain: string;
  }[];
  customerSummaryData: [];
  userPermissions: { permission: string[] };
  customerSitesDataList: [];
  sitesGetListDataLoader: boolean;
  emergency_address_data: EmergencyAddressType | {};
}

const initialState: InitialState = {
  customerData: {},
  customerusersData: [],
  isValidDomain: undefined,
  extensionMessage: "",
  domainMessage: "",
  zohoDetails: [],
  customerSummaryData: [],
  userPermissions: { permission: [] },
  customerSitesDataList: [],
  sitesGetListDataLoader: false,
  emergency_address_data: {},
};

export const getCustomerList = createAsyncThunk(
  "customer-list/list",
  async (params: any) => {
    return await customerListGet(params);
  },
);

export const getCustomerUsersdata = createAsyncThunk(
  "customeruser-list/list",
  async (params: any) => {
    return await customerusersListGet(params);
  },
);

export const editCustomer = createAsyncThunk(
  "customer/edit",
  async (payload: any) => {
    return await customerEdit(payload);
  },
);

export const addCustomer = createAsyncThunk(
  "customer/add",
  async (payload: any) => {
    return await customerAdd(payload);
  },
);

export const updateDirectory = createAsyncThunk(
  "customer-update/directory",
  async (payload: any) => {
    return await updatecontactDirectory(payload);
  },
);

export const exportCustomerList = createAsyncThunk(
  "customer-list/export",
  async () => {
    return await inventoryListExport();
  },
);

export const domainValidate = createAsyncThunk(
  "customer-list/domain",
  async (payload: any) => {
    return await domainValidatePost(payload);
  },
);

export const extensionValidate = createAsyncThunk(
  "customer/user-list/extension",
  async (payload: any) => {
    return await extensionValidatePost(payload);
  },
);

export const getZohoDetails = createAsyncThunk(
  "customer-list/zoho_details",
  async (params: any) => {
    return await accountZohoGet(params);
  },
);

export const getCustomerSummary = createAsyncThunk(
  "customer/summary",
  async () => {
    return await customerSummaryGet();
  },
);

export const createResellerCustomerUser = createAsyncThunk(
  "customer/create-user",
  async ({ payload, customer_info }: { payload: any; customer_info: any }) => {
    return await resellerCustomerUserPost(payload, customer_info);
  },
);

export const updateResellerCustomerUser = createAsyncThunk(
  "customer/edit-user",
  async ({
    payload,
    customer_info,
    userId
  }: {
    payload: any
    customer_info: any
    userId: number
  }) => {
    return await resellerCustomerUserPut(payload, customer_info, userId)
  }
)

export const getUserPermissions = createAsyncThunk(
  "user/user-permissions",
  async (payload: any) => {
    return await userPermissionsGet(payload);
  },
);

export const createCustomerResellerUcaasSite = createAsyncThunk(
  "customer/create-customer-reseller-ucaas-site",
  async ({ payload, customer_info }: { payload: any; customer_info: any }) => {
    return await createCustomerResellerUcaasSitePost(payload, customer_info);
  },
);

export const updateCustomerResellerUcaasSite = createAsyncThunk(
  "customer/update-customer-reseller-ucaas-site",
  async ({ payload, customer_info }: { payload: any; customer_info: any }) => {
    return await updateCustomerResellerUcaasSitePut(payload, customer_info);
  },
);

export const getCustomerResellerUcaasSiteList = createAsyncThunk(
  "customeruser-list/get-customer-reseller-ucaas-site-list",
  async (params: any) => {
    return await getCustomerResellerUcaasSiteListGet(params);
  },
);

export const getCustomerResellerUcaasSiteAddressList = createAsyncThunk(
  "customeruser-list/get-customer-reseller-ucaas-site-address-list",
  async (params: any) => {
    return await getCustomerResellerUcaasSiteAddressListGet(params);
  },
);

export const getCustomerResellerUcaasSiteSingleAddress = createAsyncThunk(
  "customeruser-list/get-customer-reseller-ucaas-site-single-address",
  async (params: any) => {
    return await getCustomerResellerUcaasSiteSingleAddressGet(params);
  },
);

export const validateCustomerResellerUcaasSiteAddressDetails = createAsyncThunk(
  "customer/validate-customer-reseller-ucaas-site-address-details",
  async ({ payload, customer_info }: { payload: any; customer_info: any }) => {
    return await validateCustomerResellerUcaasSiteAddressDetailsPost(
      payload,
      customer_info,
    );
  },
);

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    clearcustomerSlice: () => initialState,
    resetDomain(state) {
      state.isValidDomain = undefined;
      state.domainMessage = "";
    },
    resetEmailDomain(state) {
      state.zohoDetails = [];
    },
    resetExtension(state) {
      state.extensionMessage = "";
    },
    clearEmergencyAddressData(state) {
      state.emergency_address_data = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getCustomerList.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.customerData = action.payload.data;
      },
    );

    builder.addCase(
      getCustomerUsersdata.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.customerusersData = action.payload;
      },
    );

    builder.addCase(
      domainValidate.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.isValidDomain = action.payload.data.valid_domain;
        state.domainMessage = action.payload.data.message;
      },
    );
    builder.addCase(
      extensionValidate.fulfilled,
      (state, action: PayloadAction<any>) => {
        if (action.payload.data.error) {
          state.extensionMessage = action.payload.data.error;
        } else {
          state.extensionMessage = action.payload.data;
        }
      },
    );
    builder.addCase(
      getZohoDetails.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.zohoDetails = action.payload.data;
      },
    );

    builder.addCase(
      getCustomerSummary.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.customerSummaryData = action.payload.data;
      },
    );
    builder.addCase(
      getUserPermissions.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.userPermissions = action.payload.data;
      },
    );
    builder
      .addCase(getCustomerResellerUcaasSiteList.pending, (state) => {
        state.sitesGetListDataLoader = true;
      })
      .addCase(
        getCustomerResellerUcaasSiteList.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.customerSitesDataList = action.payload.data;
          state.sitesGetListDataLoader = false;
        },
      )
      .addCase(getCustomerResellerUcaasSiteList.rejected, (state) => {
        state.sitesGetListDataLoader = false;
      });
    builder.addCase(
      getCustomerResellerUcaasSiteSingleAddress.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.emergency_address_data = action.payload.data;
      },
    );
  },
});

export default customerSlice.reducer;
export const {
  clearcustomerSlice,
  resetDomain,
  resetEmailDomain,
  resetExtension,
  clearEmergencyAddressData,
} = customerSlice.actions;

export const selectcustomerData = (state: RootState) =>
  state.customer.customerData;
export const useCustomerData = () => {
  const customerData = useAppSelector(selectcustomerData);
  return useMemo(() => customerData, [customerData]);
};

export const selectcustomerusersData = (state: RootState) =>
  state.customer.customerusersData;
export const useCustomerusersData = () => {
  const customerusersData = useAppSelector(selectcustomerusersData);
  return useMemo(() => customerusersData, [customerusersData]);
};

export const selectValidDomain = (state: RootState) =>
  state.customer.isValidDomain;
export const useValidDomain = () => {
  const isValidDomain = useAppSelector(selectValidDomain);
  return useMemo(() => isValidDomain, [isValidDomain]);
};

export const selectValidExtension = (state: RootState) =>
  state.customer.extensionMessage;
export const useValidExtension = () => {
  const ValidExtension = useAppSelector(selectValidExtension);
  return useMemo(() => ValidExtension, [ValidExtension]);
};

export const selectDomainMessage = (state: RootState) =>
  state.customer.domainMessage;
export const useDomainMessage = () => {
  const isDomainMessage = useAppSelector(selectDomainMessage);
  return useMemo(() => isDomainMessage, [isDomainMessage]);
};

export const selectEmailDomain = (state: RootState) =>
  state.customer.zohoDetails;
export const useEmailDomain = () => {
  const zohoDetails = useAppSelector(selectEmailDomain);
  return useMemo(() => zohoDetails, [zohoDetails]);
};

export const selectCustomerSummaryData = (state: RootState) =>
  state.customer.customerSummaryData;
export const useCustomerSummaryData = () => {
  const customerSummary = useAppSelector(selectCustomerSummaryData);
  return useMemo(() => customerSummary, [customerSummary]);
};

export const selectUserPermissionsData = (state: RootState) =>
  state.customer.userPermissions;
export const useUserPermissions = () => {
  const userPermission = useAppSelector(selectUserPermissionsData);
  return useMemo(() => userPermission, [userPermission]);
};

export const customerSitesDataListSelector = (state: RootState) =>
  state.customer.customerSitesDataList;

export const customerSitesDataListLoaderSelector = (state: RootState) =>
  state.customer.sitesGetListDataLoader;

export const customerSitesSingleAddressDataSelector = (state: RootState) =>
  state.customer.emergency_address_data;
