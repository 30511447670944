function MoreIcon() {
  return (
    <span className="anticon anticon-MoreIcon ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          d="M6.33341 12C6.33341 12.7364 5.7364 13.3334 5.00004 13.3334C4.26368 13.3334 3.66667 12.7364 3.66667 12C3.66667 11.2637 4.26368 10.6667 5.00004 10.6667C5.73653 10.6667 6.33341 11.2636 6.33341 12ZM10.6668 12C10.6668 11.2637 11.2638 10.6667 12.0002 10.6667C12.7365 10.6667 13.3335 11.2637 13.3335 12C13.3335 12.7364 12.7365 13.3334 12.0002 13.3334C11.2638 13.3334 10.6668 12.7364 10.6668 12ZM17.6666 12C17.6666 11.2637 18.2636 10.6667 19 10.6667C19.7363 10.6667 20.3333 11.2637 20.3333 12C20.3333 12.7364 19.7363 13.3334 19 13.3334C18.2636 13.3334 17.6666 12.7364 17.6666 12Z"
          stroke="white"
          strokeWidth="1.33333"
        />
      </svg>
    </span>
  );
}

export default MoreIcon;
