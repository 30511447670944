import {
  allowedPortingValues,
  allowedSecureFaxValues,
  allowedUcaasValues
} from "../../constants/allowedRoles"
import usePartnerStatus from "../../hooks/usePartnerStatus"
import {
  getuserRolesList,
  useUserRolesList
} from "../../pages/userRoles/userRolesSlice"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { RootState } from "../../store/store"
import Button from "../Button/Button"
import { MenuList } from "./MenuItems"
import Sibarstyle, { PopoverStyles } from "./Sidebar.style"
import { Col, Flex, message, Popover, Row, Skeleton } from "antd"
import { useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import MoreIcon from "../Icons/MoreIcon"
import { customerOptions } from "../../constants/userDetailPermissions"
import { UserRoleType } from "../../types/userRolesPermissions"
import { getRolePriority } from "../../utils/role"

export interface MenuListTypes {
  id: string
  icon: React.ElementType
  title: string
  url: string
  target?: string
}

function Sidebar() {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const rolesList = useUserRolesList()
  const { loadingPartnerOnboarding } = usePartnerStatus()
  const { isPartnerOnboarding } = useAppSelector(
    (state: RootState) => state.partner
  )
  const rolesData = rolesList?.data?.roles_list
  const ucaasValue = rolesData?.UCaaS
  const secureFaxValue = rolesData?.SecureFax
  const portingValue = rolesData?.Porting
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [overflowItems, setOverflowItems] = useState<MenuListTypes[]>([]); // Items that go into "More"
  const [sidebarHeight, setSidebarHeight] = useState(0);
  const [open, setOpen] = useState(false);
  const approvedRolesList = Object.keys(rolesList?.data?.roles_list || []).map(
    (r) => r.toLowerCase()
  )
  const customerRolePriority = getRolePriority(
    rolesData?.Customer,
    customerOptions
  )
  const customerOfficeManagerRolePriority = getRolePriority(
    UserRoleType.OfficeManager,
    customerOptions
  )
  const customerSiteManagerRolePriority = getRolePriority(
    UserRoleType.SiteManager,
    customerOptions
  )

  const menuFilter = (x: { id: string }) =>
    x.id === "dashboard" ||
    ((allowedPortingValues.has(portingValue) ||
      allowedSecureFaxValues.has(secureFaxValue) ||
      allowedUcaasValues.has(ucaasValue)) &&
      x.id === "porting") ||
    (rolesData?.Customer && (x.id === "customer" || x.id === "users")) ||
    approvedRolesList.includes(x.id.toLowerCase())

    const fetchRoles = async () => {
      setIsLoading(true)
      try {
        const res = await dispatch(getuserRolesList()).unwrap()
        if (res?.error) {
          navigate("/server-error")
          message.error("An error occurred while fetching user data.")
        } else {
          return res
        }
      } catch (er: any) {
        message.error("An error occurred while fetching user data.")
      } finally {
        setIsLoading(false)
      }
    }
  
    useEffect(() => {
      fetchRoles()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  const menuUpdatedList = useMemo(() => {
    if (isLoading) {
      return []
    }
    if (isPartnerOnboarding) {
      return MenuList.filter((item) => item.id === "dashboard")
    }
    let updateData: MenuListTypes[] = [...MenuList]
    // customer role is super user or reseller then hide Users menu and show Customers menu
    if (
      customerRolePriority &&
      customerRolePriority < customerOfficeManagerRolePriority
    ) {
      updateData = updateData?.filter((item) => item?.title !== "Users")
    }
    // customer role is office manager or office manager read only then hide Customers menu and show Users menu
    if (
      [UserRoleType.OfficeManager, UserRoleType.OfficeManagerReadOnly].includes(rolesData?.Customer)
    ) {
      updateData = updateData?.filter((item) => item?.title !== "Customers")
    }
    // customer role is site manager or below site manager then hide Customers and Users menu
    if(customerRolePriority >= customerSiteManagerRolePriority) {
      updateData = updateData?.filter((item) => item?.title !== "Customers" && item?.title !== "Users")
    }
    if (!rolesData?.UCaaS || rolesData?.UCaaS === "No Portal") {
      updateData = updateData.filter((item) => item?.title !== "UCaaS")
    }
    if (!rolesData?.AlertBlast) {
      updateData = updateData.filter((item) => item?.title !== "AlertBlast")
    }
    if (!rolesData?.Billing) {
      updateData = updateData.filter((item) => item?.title !== "Billing")
    }
    if (!rolesData?.Teams) {
      updateData = updateData.filter((item) => item?.title !== "Teams")
    }
    if (!rolesData?.LMS) {
      updateData = updateData.filter((item) => item?.title !== "Training")
    }
    return updateData?.filter((x: MenuListTypes) => menuFilter(x))
  }, [
    isLoading,
    isPartnerOnboarding,
    rolesData?.Customer,
    rolesData?.UCaaS,
    rolesData?.AlertBlast,
    rolesData?.Billing,
    rolesData?.Teams,
    rolesData?.LMS
  ])

  const [visibleItems, setVisibleItems] = useState<MenuListTypes[]>(menuUpdatedList); // Items visible in the sidebar

  useEffect(() => {
    const handleResize = () => {
      const sidebarElement = document.querySelector(".sidebar");
      if (sidebarElement instanceof HTMLElement) {
        setSidebarHeight(sidebarElement.offsetHeight);
        adjustMenuItems();
      }
    };

    // Adjust on mount and window resize
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial adjustment

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [menuUpdatedList,sidebarHeight]);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  // Dynamically adjust the menu based on the height
  const adjustMenuItems = () => {
    const itemHeight = 67; // Approximate height of each menu item including padding/margin
    const availableHeight = sidebarHeight - 100; // Account for padding or other components
    const itemsThatFit = Math.floor(availableHeight / itemHeight);

    setVisibleItems(menuUpdatedList.slice(0, itemsThatFit));
    setOverflowItems(menuUpdatedList.slice(itemsThatFit));
  };

  const handleMenuClick = (selectedItem:MenuListTypes) => {
    // Move the selected item to the end of visible items
    const updatedVisibleItems = [...visibleItems];
    const updatedOverflowItems = [...overflowItems];

    // Remove the selected item from overflow items
    const selectedIndex = updatedOverflowItems.findIndex(
      (item) => item.id === selectedItem.id
    );
    if (selectedIndex > -1) {
      updatedOverflowItems.splice(selectedIndex, 1);
    }

    // Add the last visible item to the overflow list
    const lastVisibleItem = updatedVisibleItems.pop();
    lastVisibleItem && updatedOverflowItems.unshift(lastVisibleItem);

    // Add the selected item to the end of visible items
    updatedVisibleItems.push(selectedItem);

    setVisibleItems(updatedVisibleItems);
    setOverflowItems(updatedOverflowItems);

    // Redirect to the selected item's path
    navigate(selectedItem.url);
    hide();
  };

  return (
    <Sibarstyle>
      <div className="sidebar">
        {isLoading || loadingPartnerOnboarding ? (
          <Flex vertical align="center" gap={8} style={{ marginTop: "8px" }}>
            {Array.from(Array(5).keys()).map((index) => (
              <Skeleton.Avatar key={index} active size={60} shape="square" />
            ))}
          </Flex>
        ) : (
          <ul>
            {rolesData &&
              visibleItems.map((val: MenuListTypes) => {
                const Icon = val?.icon
                const array = location?.pathname?.split("/")
                const new_url = val?.url?.split("/")

                const isSelected =
                  (array[1] &&
                    (val?.url === `/${array[1]}` ||
                      new_url[1] === `${array[1]}`)) ||
                  (location.pathname === "/" && val?.id === "dashboard")

                return (
                  <li key={val.id}>
                    <Button
                      target={val?.target}
                      type="link"
                      size="small"
                      href={val?.url}
                      icon={<Icon />}
                      className={[
                        isSelected ? "active" : "",
                        val?.target ? "target-style" : ""
                      ]
                        .filter(Boolean)
                        .join(" ")}
                    >
                      {val?.title}
                    </Button>
                  </li>
                )
              })}
            {overflowItems.length > 0 && (
              <Popover
                open={open}
                onOpenChange={handleOpenChange}
                overlayClassName="popover-sidebar-overlay"
                content={
                  <PopoverStyles>
                    <div className="popover-sidebar">
                      <Row gutter={12}>
                        {overflowItems.map((item) => {
                          const Icon = item?.icon
                          return (
                            <Col
                              xs={8}
                              key={item?.id}
                              onClick={() => handleMenuClick(item)}
                              className="sidebar-item"
                            >
                              <div className="icon-wrapper">
                                <Icon />
                              </div>
                              <span>{item?.title}</span>
                            </Col>
                          )
                        })}
                      </Row>
                    </div>
                  </PopoverStyles>
                }
                trigger="click"
                placement="right"
              >
                <li>
                  <Button
                    type="link"
                    href={location.pathname}
                    size="small"
                    icon={<MoreIcon />}
                    className={open ? "active" : ""}
                  >
                    More
                  </Button>
                </li>
              </Popover>
            )}
          </ul>
        )}
      </div>
    </Sibarstyle>
  )
}

export default Sidebar
