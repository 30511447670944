import React, { ComponentType, lazy, LazyExoticComponent, Suspense } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { Routes, Route } from "react-router-dom"
import ProtectedRoute from "./ProtectedRoute"
import Loader from "../Loader/Loader"
import InternalServerError from "../../pages/ServerError/InternalServerError"

const Loadable = <T extends ComponentType<any>>(
  LazyComponent: LazyExoticComponent<T>
): React.FC<React.ComponentProps<T>> => {
  return (props: any) => (
    <Suspense
      fallback={
        <div
          style={{
            height: "calc(100vh - 84px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Loader />
        </div>
      }
    >
      <LazyComponent {...props} />
    </Suspense>
  )
}

// Lazy loading components
const Inventory = Loadable(
  lazy(() => import("../../pages/inventory/Inventory"))
)
const Numbers = Loadable(lazy(() => import("../../pages/numbers/Numbers")))
const CustomerDetails = Loadable(
  lazy(() => import("../../pages/customer/CustomerDetails"))
)
const SitesDetails = Loadable(
  lazy(() => import("../../pages/customer/SitesDetails"))
)
const SentFaxAdmin = Loadable(
  lazy(() => import("../../pages/sentFaxAdmin/SentFaxAdmin"))
)
const Orders = Loadable(lazy(() => import("../../pages/orders/Orders")))
const CreateOrder = Loadable(
  lazy(() => import("../../pages/orders/CreateOrder"))
)
const Teams = Loadable(lazy(() => import("../../pages/teams/teams")))
const Billing = Loadable(lazy(() => import("../../pages/billing/billing")))
const Ucaas = Loadable(lazy(() => import("../../pages/ucaas/ucaas")))
const Support = Loadable(lazy(() => import("../../pages/support/support")))
const OrderDetails = Loadable(
  lazy(() => import("../../pages/orders/OrderDetails"))
)
const PortDetails = Loadable(
  lazy(() => import("../../pages/orders/PortDetails"))
)
const PortRequest = Loadable(
  lazy(() => import("../../pages/orders/PortRequest"))
)
const Partner = Loadable(lazy(() => import("../../pages/partner/Partner")))
const RegisterDeal = Loadable(
  lazy(() => import("../../pages/partner/RegisterDeal"))
)
const DealDetails = Loadable(
  lazy(() => import("../../pages/partner/DealDetails"))
)
const NewAsset = Loadable(lazy(() => import("../../pages/partner/NewAsset")))
const NewAssetEdit = Loadable(
  lazy(() => import("../../pages/partner/NewAssetEdit"))
)
const AccountDetails = Loadable(
  lazy(() => import("../../pages/partner/AccountDetails"))
)
const Training = Loadable(lazy(() => import("../../pages/Training/Training")))
const OperationsEngineer = Loadable(
  lazy(() => import("../../pages/Training/OperationsEngineer"))
)
const EditTrack = Loadable(lazy(() => import("../../pages/Training/EditTrack")))
const CustomerDetailSummaryOrder = lazy(
  () => import("../../pages/customer/CustomerDetailsSummaryOrder")
)
const CustomerOrderSummary = lazy(
  () => import("../../pages/customer/CustomerOrderSummary")
)
const TryAgainLogin = Loadable(
  lazy(() => import("../../pages/tryAgainLogin/tryAgainLogin"))
)
const Reseller = Loadable(lazy(() => import("../../pages/reseller/Reseller")))
const Users = Loadable(lazy(() => import("../../pages/Users/Users")))
const ResellerCustomer = Loadable(
  lazy(() => import("../../pages/reseller/ResellerCustomer"))
)
const Customers = Loadable(lazy(() => import("../../pages/customer/Customers")))
const ResellerCustomerUser = Loadable(
  lazy(() => import("../../pages/reseller/ResellerCustomerUser"))
)
const AddOnDeal = Loadable(lazy(() => import("../../pages/partner/AddOnDeal")))
const Dashboard = Loadable(
  lazy(() => import("../../pages/dashboard/Dashboard"))
)
const SupportedDevices = Loadable(
  lazy(() => import("../../pages/SupportedDevices/SupportedDevices"))
)
const BrandGuidelines = Loadable(
  lazy(() => import("../../pages/brandGuidelines/BrandGuidelines"))
)
const NotFound = Loadable(lazy(() => import("../../pages/notFound/NotFound")))

const AppRoutes = () => {
  const { isAuthenticated } = useAuth0()
  return (
    <>
      {isAuthenticated ? (
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route
              path="/dashboard/supported-devices"
              element={<SupportedDevices />}
            />
            <Route
              path="/dashboard/brand-guidelines"
              element={<BrandGuidelines />}
            />

            <Route path="/customer/:tabId" element={<Customers />} />
            <Route path="/numbers" element={<Numbers />} />
            <Route
              path="/customer/reseller/:customerId/customer"
              element={<ResellerCustomer />}
            />
            <Route
              path="/customer/reseller/:customerId/customer/:userId/users"
              element={<ResellerCustomerUser />}
            />
            <Route
              path="/customer/domains/:customerId/domain/:userId/users"
              element={<ResellerCustomerUser isDomainsUsers />}
            />
            <Route path="/users" element={<Users />} />
            <Route
              path="/customer/:reseller/:tabId"
              element={<CustomerDetails />}
            />
            <Route
              path="/customer/customer-details/summary/order"
              element={<CustomerDetailSummaryOrder />}
            />
            <Route
              path="/customer/customer-details/sites/sites-details"
              element={<SitesDetails />}
            />
            <Route
              path="/customer/customer-details/order/summary"
              element={<CustomerOrderSummary />}
            />

            <Route path="/orders" element={<Orders />} />
            <Route path="/numbers/:tabId" element={<Numbers />} />
            <Route path="/orders/create-order" element={<CreateOrder />} />
            <Route path="/orders/order-details" element={<OrderDetails />} />
            <Route path="/orders/port-details" element={<PortDetails />} />
            <Route path="/orders/port-request" element={<PortRequest />} />
            <Route path="/secure-fax" element={<SentFaxAdmin />} />
            <Route path="/partner/:tabId" element={<Partner />} />

            <Route path="/partner/register-deal" element={<RegisterDeal />} />
            <Route path="/partner/add-on-deal" element={<AddOnDeal />} />
            <Route path="/partner/deal-details" element={<DealDetails />} />
            <Route path="/partner/resource/new-asset" element={<NewAsset />} />
            <Route
              path="/partner/resource/new-asset-edit"
              element={<NewAssetEdit />}
            />
            {/* <Route path="/partner/account" element={<AddEditAccount />} /> */}
            <Route
              path="/partner/account/details"
              element={<AccountDetails />}
            />
            <Route
              path="/partner/account/details/:tabId"
              element={<AccountDetails />}
            />
            <Route path="/training/:tabId" element={<Training />} />
            <Route
              path="/training/learning-track/:track_id/operations-engineer"
              element={<OperationsEngineer />}
            />
            <Route
              path="/training/learning-track/:track_id/operations-engineer/:lesson_id"
              element={<OperationsEngineer />}
            />
            <Route
              path="/training/learning-track/operations-engineer/edit-track"
              element={<EditTrack />}
            />
            <Route path="/billing" element={<Billing />} />
            <Route path="/ucaas" element={<Ucaas />} />
            <Route path="/support" element={<Support />} />
            {localStorage?.getItem("showTeams") === "Reseller" ||
            localStorage?.getItem("showTeams") === "Super User" ? (
              <Route path="/teams" element={<Teams />} />
            ) : null}
          </Route>
          <Route path="/relogin" element={<TryAgainLogin />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/server-error" element={<InternalServerError />} />
        </Routes>
      ) : null}
      <Routes>
        <Route path="/relogin" element={<TryAgainLogin />} />
      </Routes>
    </>
  )
}

export default AppRoutes
