function TrainingIcon() {
  return (
    <span
      className="anticon anticon-alert"
      style={{ position: "relative", display: "inline-block" }}
    >
      <svg
        width="26"
        height="24"
        viewBox="0 0 26 24"
        fill="currentcolor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.72727 20.1941H24.2727C24.6738 20.1941 25 19.8679 25 19.4668V17.7534C25 17.3523 24.6738 17.0261 24.2727 17.0261H23.5455V4.09091C23.5455 3.48935 23.0561 3 22.4545 3H3.54545C2.94389 3 2.45455 3.48935 2.45455 4.09091V17.0261C2.25732 17.0703 1 16.7803 1 17.7534V19.4668C1 19.8679 1.32617 20.1941 1.72727 20.1941ZM3.18182 4.09091C3.18182 3.89045 3.34499 3.72727 3.54545 3.72727H22.4545C22.655 3.72727 22.8182 3.89045 22.8182 4.09091V17.0261H3.18182V4.09091ZM1.72727 17.7534C3.95987 17.7534 21.7939 17.7534 24.2727 17.7534L24.2733 19.4668H1.72727V17.7534Z"
          strokeWidth="0.4"
        />
        <path
          d="M13.4763 18.2461H12.5233C12.3225 18.2461 12.1597 18.4089 12.1597 18.6097C12.1597 18.8105 12.3225 18.9734 12.5233 18.9734H13.4763C13.6771 18.9734 13.8399 18.8105 13.8399 18.6097C13.8399 18.4089 13.6771 18.2461 13.4763 18.2461Z"
          strokeWidth="0.4"
        />
        <path
          d="M10.0261 14.3789H15.9766C16.5418 14.3789 17.0015 13.9192 17.0015 13.354V7.40359C17.0015 6.8386 16.5418 6.37891 15.9766 6.37891H10.0261C9.46116 6.37891 9.00146 6.8386 9.00146 7.40359V13.354C9.00146 13.9192 9.46116 14.3789 10.0261 14.3789ZM9.72874 7.40359C9.72874 7.23686 9.85942 7.10618 10.0261 7.10618H15.9766C16.1407 7.10618 16.2742 7.23952 16.2742 7.40359V13.354C16.2742 13.5181 16.1407 13.6516 15.9766 13.6516H10.0261C9.86208 13.6516 9.72874 13.5181 9.72874 13.354V7.40359Z"
          strokeWidth="0.4"
        />
        <path
          d="M12.2056 12.3771C12.3143 12.3771 12.4217 12.3473 12.5169 12.2905L14.8375 10.896C15.0194 10.7852 15.1277 10.5916 15.1277 10.378C15.1275 10.1644 15.019 9.97088 14.8358 9.85937L12.516 8.46325C12.4217 8.40678 12.3143 8.37695 12.2056 8.37695C11.8714 8.37695 11.5996 8.64862 11.5996 8.9826V11.7713C11.5996 12.1053 11.8714 12.3771 12.2056 12.3771ZM12.3269 9.19815L14.2873 10.3782L12.3269 11.5561V9.19815Z"
          strokeWidth="0.4"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-box-arrow-in-up-right"
        viewBox="0 0 16 16"
        style={{ position: "absolute", top: "-10px", right: "-10px" }}
      >
        <path
          fillRule="evenodd"
          d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5"
        />
        <path
          fillRule="evenodd"
          d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0z"
        />
      </svg>
    </span>
  )
}

export default TrainingIcon
