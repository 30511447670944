import {
  RoleOption,
  Roles,
  ServiceDetails,
} from "../types/userRolesPermissions";

export default function handleFilterRoles(
  item: ServiceDetails,
  rolesData: Roles,
) {
  const role = rolesData[item.label] ?? "";
  const loggedInUserRolePriority =
    item.option?.find((option) => option.label.includes(role))?.priority || 0;
  return item.option?.filter(
    (role: RoleOption) =>
      role.priority >= loggedInUserRolePriority || !role.priority, // !role.priority condition used for show none option
  );
}

export const getRolePriority = (role: string, options: any[], defaultPriority = 0) =>
  options?.find((option) => option.label.includes(role))?.priority ||
  defaultPriority;
