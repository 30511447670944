export const uCaaSOptions = [
  { value: "", label: "None", priority: 0 },
  {
    value: "uCaaSCallCenterSupervisor",
    label: "UCaaS Call Center Supervisor",
    priority: 5,
  },
  { value: "uCaaSNoPortal", label: "UCaaS No Portal", priority: 10 },
  { value: "uCaaSOfficeManager", label: "UCaaS Office Manager", priority: 3 },
  { value: "uCaaSReseller", label: "UCaaS Reseller", priority: 2 },
  { value: "uCaaSSimpleUser", label: "UCaaS Simple User", priority: 9 },
  { value: "uCaaSSiteManager", label: "UCaaS Site Manager", priority: 4 },
  { value: "uCaaSSuperUser", label: "UCaaS Super User", priority: 1 },
  { value: "uCaaSAdvancedUser", label: "UCaaS Advanced User", priority: 7 },
  { value: "uCaaSBasicUser", label: "UCaaS Basic User", priority: 8 },
  {
    value: "uCaaSCallCenterAgent",
    label: "UCaaS Call Center Agent",
    priority: 6,
  },
];

export const secureFaxOptions = [
  { value: "", label: "None", priority: 0 },
  {
    value: "secureFaxOfficeManager",
    label: "SecureFax Office Manager",
    priority: 5,
  },
  {
    value: "secureFaxOfficeManagerReadOnly",
    label: "SecureFax Office Manager Read Only",
    priority: 6,
  },
  { value: "secureFaxReseller", label: "SecureFax Reseller", priority: 3 },
  {
    value: "secureFaxResellerReadOnly",
    label: "SecureFax Reseller Read Only",
    priority: 4,
  },
  { value: "secureFaxSuperUser", label: "SecureFax Super User", priority: 1 },
  {
    value: "secureFaxsuperUserReadOnly",
    label: "SecureFax Super User Read Only",
    priority: 2,
  },
];

export const portingOptions = [
  { value: "", label: "None", priority: 0 },
  {
    value: "portingOfficeManager",
    label: "Porting Office Manager",
    priority: 5,
  },
  {
    value: "portingOfficeManagerReadOnly",
    label: "Porting Office Manager Read Only",
    priority: 6,
  },
  { value: "portingReseller", label: "Porting Reseller", priority: 3 },
  {
    value: "portingResellerReadOnly",
    label: "Porting Reseller Read Only",
    priority: 4,
  },
  { value: "portingSuperUser", label: "Porting Super User", priority: 1 },
  {
    value: "portingSuperUserReadOnly",
    label: "Porting Super User Read Only",
    priority: 2,
  },
];

export const alertBlastOptions = [
  { value: "", label: "None", priority: 0 },
  {
    value: "alertBlastOfficeManager",
    label: "AlertBlast Office Manager",
    priority: 5,
  },
  {
    value: "alertBlastOfficeManagerReadOnly",
    label: "AlertBlast Office Manager Read Only",
    priority: 6,
  },
  { value: "alertBlastReseller", label: "AlertBlast Reseller", priority: 3 },
  {
    value: "alertBlastResellerReadOnly",
    label: "AlertBlast Reseller Read Only",
    priority: 4,
  },
  { value: "alertBlastSuperUser", label: "AlertBlast Super User", priority: 1 },
  {
    value: "alertBlastSuperUserReadOnly",
    label: "AlertBlast Super User Read Only",
    priority: 2,
  },
];

export const billingOptions = [
  { value: "", label: "None", priority: 0 },
  { value: "billingAgent", label: "Billing Agent", priority: 10 },
  { value: "billingPartner", label: "Billing Partner", priority: 9 },
  {
    value: "billingOfficeManager",
    label: "Billing Office Manager",
    priority: 5,
  },
  {
    value: "billingOfficeManagerReadOnly",
    label: "Billing Office Manager Read Only",
    priority: 6,
  },
  { value: "billingReseller", label: "Billing Reseller", priority: 3 },
  {
    value: "billingResellerReadOnly",
    label: "Billing Reseller Read Only",
    priority: 4,
  },
  { value: "billingSiteManager", label: "Billing Site Manager", priority: 7 },
  {
    value: "billingSiteManagerReadOnly",
    label: "Billing Site Manager Read Only",
    priority: 8,
  },
  { value: "billingSuperUser", label: "Billing Super User", priority: 1 },
  {
    value: "billingSuperUserReadOnly",
    label: "Billing Super User Read Only",
    priority: 2,
  },
];

export const teamOptions = [
  { value: "", label: "None", priority: 0 },
  { value: "teamsOfficeManager", label: "Teams Office Manager", priority: 5 },
  {
    value: "teamsOfficeManagerReadOnly",
    label: "Teams Office Manager Read Only",
    priority: 6,
  },
  { value: "teamsReseller", label: "Teams Reseller", priority: 3 },
  {
    value: "teamsResellerReadOnly",
    label: "Teams Reseller Read Only",
    priority: 4,
  },
  { value: "teamsSiteManager", label: "Teams Site Manager", priority: 7 },
  {
    value: "teamsSiteManagerReadOnly",
    label: "Teams Site Manager Read Only",
    priority: 8,
  },
  { value: "teamsSuperUser", label: "Teams Super User", priority: 1 },
  {
    value: "teamsSuperUserReadOnly",
    label: "Teams Super User Read Only",
    priority: 2,
  },
];

export const customerOptions = [
  { value: "", label: "None", priority: 0 },
  { value: "customerSiteManager", label: "Customer Site Manager", priority: 7 },

  {
    value: "customerSiteManagerReadOnly",
    label: "Customer Site Manager Read Only",
    priority: 8,
  },

  { value: "customerSuperUser", label: "Customer Super User", priority: 1 },

  {
    value: "customerSuperUserReadOnly",
    label: "Customer Super User Read Only",
    priority: 2,
  },

  {
    value: "customerOfficeManager",
    label: "Customer Office Manager",
    priority: 5,
  },

  {
    value: "customerOfficeManagerReadOnly",
    label: "Customer Office Manager Read Only",
    priority: 6,
  },

  { value: "customerReseller", label: "Customer Reseller", priority: 3 },

  {
    value: "customerResellerReadOnly",
    label: "Customer Reseller Read Only",
    priority: 4,
  },
];

export const partnerOPtions = [
  { value: "", label: "None", priority: 0 },
  { value: "partnerAdmin", label: "Partner Admin", priority: 2 },
  { value: "partnerTSD", label: "Partner TSD", priority: 4 },
  { value: "partnerSuperUser", label: "Partner Super User", priority: 1 },
  { value: "partnerUser", label: "Partner User", priority: 3 },
];

export const lmsOptions = [
  { value: "", label: "None", priority: 0 },
  { value: "lmsPartner", label: "LMS Partner", priority: 3 },
  { value: "lmsUser", label: "LMS User", priority: 2 },
  { value: "lmsSuperUser", label: "LMS Super User", priority: 1 },
];

export const permissionsData = [
  {
    label: "UCaaS",
    name: "permissionUCaaS",
    option: uCaaSOptions,
  },
  {
    label: "SecureFax",
    name: "permissionSecureFax",
    option: secureFaxOptions,
  },
  {
    label: "AlertBlast",
    name: "permissionAlertBlast",
    option: alertBlastOptions,
  },
  {
    label: "Teams",
    name: "permissionTeams",
    option: teamOptions,
  },
  {
    label: "Porting",
    name: "permissionPorting",
    option: portingOptions,
  },
  {
    label: "Billing",
    name: "permissionBilling",
    option: billingOptions,
  },

  {
    label: "Customer",
    name: "permissionCustomer",
    option: customerOptions,
  },
  {
    label: "Partner",
    name: "permissionPartner",
    option: partnerOPtions,
  },
  {
    label: "LMS",
    name: "permissionLMS",
    option: lmsOptions,
  },
];

export const UCaaSServiceData = {
  header: {
    label: "UCaaS",
    name: "UCaaS",
    checked: true,
  },
  body: [
    {
      label: "Office manager",
      name: "officeManager",
      checked: false,
    },
    {
      label: "Call center supervisor",
      checked: false,
      name: "callCenterSupervisor",
    },
    {
      label: "Modern user",
      checked: false,
      name: "modernUser",
    },
    {
      label: "Call center agent",
      checked: false,
      name: "callCenterAgent",
    },
  ],
};

export const SecureFaxData = {
  header: {
    label: "SecureFax",
    name: "secureFax",
    checked: false,
  },
  body: [
    {
      did: "7045555510",
      license: 3,
      license_name: "Email Fax Standard",
      assigned_users: [
        {
          username: "mfisher1@telware.com",
          id: 9390,
        },
      ],
    },
    {
      did: "7045555559",
      license: 3,
      license_name: "Email Fax Standard",
      assigned_users: [
        {
          username: "mfisher3@telware.com",
          id: 9397,
        },
        {
          username: "mfisher1@telware.com",
          id: 9390,
        },
      ],
    },
    {
      did: "7045555556",
      license: 3,
      license_name: "Email Fax Standard",
      assigned_users: [],
    },
    {
      did: "9195027461",
      license: 6,
      license_name: "unassigned",
      assigned_users: [],
    },
  ],
};

export const DEFAULT_COUNTRY_LIST = [
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Brazil", label: "Brazil" },
  { value: "Brunei", label: "Brunei" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cabo Verde", label: "Cabo Verde" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  {
    value: "Congo, Democratic Republic of the",
    label: "Congo, Democratic Republic of the",
  },
  { value: "Congo, Republic of the", label: "Congo, Republic of the" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czech Republic", label: "Czech Republic" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "East Timor", label: "East Timor" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Eswatini", label: "Eswatini" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Greece", label: "Greece" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "Korea, North", label: "Korea, North" },
  { value: "Korea, South", label: "Korea, South" },
  { value: "Kosovo", label: "Kosovo" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  { value: "Laos", label: "Laos" },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mexico", label: "Mexico" },
  { value: "Micronesia", label: "Micronesia" },
  { value: "Moldova", label: "Moldova" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "North Macedonia", label: "North Macedonia" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Palestine", label: "Palestine" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Qatar", label: "Qatar" },
  { value: "Romania", label: "Romania" },
  { value: "Russia", label: "Russia" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  { value: "South Sudan", label: "South Sudan" },
  { value: "Spain", label: "Spain" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syria", label: "Syria" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania", label: "Tanzania" },
  { value: "Thailand", label: "Thailand" },
  { value: "Togo", label: "Togo" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States", label: "United States" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Vatican City", label: "Vatican City" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" },
];

export const DEFAULT_TIMEZONES = [
  { value: "Pacific/Midway", label: "Midway Island, Samoa" },
  { value: "America/Adak", label: "Hawaii-Aleutian" },
  { value: "Etc/GMT+10", label: "Hawaii" },
  { value: "Pacific/Marquesas", label: "Marquesas Islands" },
  { value: "Pacific/Gambier", label: "Gambier Islands" },
  { value: "America/Anchorage", label: "Alaska" },
  { value: "America/Ensenada", label: "Ensenada" },
  { value: "America/Los_Angeles", label: "Pacific Time (US & Canada)" },
  { value: "America/Denver", label: "Mountain Time (US & Canada)" },
  { value: "America/Phoenix", label: "Arizona" },
  { value: "America/Chihuahua", label: "Chihuahua, La Paz, Mazatlan" },
  { value: "America/Chicago", label: "Central Time (US & Canada)" },
  { value: "America/Regina", label: "Saskatchewan" },
  {
    value: "America/Mexico_City",
    label: "Guadalajara, Mexico City, Monterrey",
  },
  { value: "America/New_York", label: "Eastern Time (US & Canada)" },
  { value: "America/Indianapolis", label: "Indiana (East)" },
  { value: "America/Bogota", label: "Bogota, Lima, Quito, Rio Branco" },
  { value: "America/Caracas", label: "Caracas" },
  { value: "America/Santiago", label: "Santiago" },
  { value: "America/La_Paz", label: "La Paz" },
  { value: "America/Campo_Grande", label: "Brasilia" },
  { value: "America/Argentina/Buenos_Aires", label: "Buenos Aires" },
  { value: "America/Godthab", label: "Greenland" },
  { value: "America/Montevideo", label: "Montevideo" },
  { value: "Atlantic/South_Georgia", label: "Mid-Atlantic" },
  { value: "Atlantic/Azores", label: "Azores" },
  { value: "Atlantic/Cape_Verde", label: "Cape Verde Is." },
  { value: "Europe/Dublin", label: "Dublin" },
  { value: "Europe/Lisbon", label: "Lisbon" },
  { value: "Europe/London", label: "London" },
  { value: "Africa/Casablanca", label: "Casablanca" },
  { value: "Africa/Monrovia", label: "Monrovia" },
  { value: "Etc/UTC", label: "UTC" },
  {
    value: "Europe/Belgrade",
    label: "Belgrade, Bratislava, Budapest, Ljubljana, Prague",
  },
  { value: "Europe/Sarajevo", label: "Sarajevo, Skopje, Warsaw, Zagreb" },
  { value: "Europe/Brussels", label: "Brussels, Copenhagen, Madrid, Paris" },
  {
    value: "Europe/Amsterdam",
    label: "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
  },
  { value: "Africa/Algiers", label: "West Central Africa" },
  { value: "Europe/Bucharest", label: "Bucharest" },
  { value: "Africa/Cairo", label: "Cairo" },
  {
    value: "Europe/Helsinki",
    label: "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
  },
  { value: "Europe/Athens", label: "Athens, Istanbul, Minsk" },
  { value: "Asia/Jerusalem", label: "Jerusalem" },
  { value: "Africa/Harare", label: "Harare, Pretoria" },
  { value: "Europe/Moscow", label: "Moscow, St. Petersburg, Volgograd" },
  { value: "Asia/Kuwait", label: "Kuwait, Riyadh, Baghdad" },
  { value: "Africa/Nairobi", label: "Nairobi" },
  { value: "Asia/Baghdad", label: "Baghdad" },
  { value: "Asia/Tehran", label: "Tehran" },
  { value: "Asia/Dubai", label: "Abu Dhabi, Muscat" },
  { value: "Asia/Baku", label: "Baku" },
  { value: "Asia/Kabul", label: "Kabul" },
  { value: "Asia/Yekaterinburg", label: "Yekaterinburg" },
  { value: "Asia/Karachi", label: "Islamabad, Karachi, Tashkent" },
  { value: "Asia/Calcutta", label: "Chennai, Kolkata, Mumbai, New Delhi" },
  { value: "Asia/Kathmandu", label: "Kathmandu" },
  { value: "Asia/Dhaka", label: "Astana, Dhaka" },
  { value: "Asia/Colombo", label: "Sri Jayawardenepura" },
  { value: "Asia/Almaty", label: "Almaty, Novosibirsk" },
  { value: "Asia/Rangoon", label: "Yangon (Rangoon)" },
  { value: "Asia/Bangkok", label: "Bangkok, Hanoi, Jakarta" },
  { value: "Asia/Krasnoyarsk", label: "Krasnoyarsk" },
  { value: "Asia/Shanghai", label: "Beijing, Chongqing, Hong Kong, Urumqi" },
  { value: "Asia/Kuala_Lumpur", label: "Kuala Lumpur, Singapore" },
  { value: "Asia/Taipei", label: "Taipei" },
  { value: "Australia/Perth", label: "Perth" },
  { value: "Asia/Irkutsk", label: "Irkutsk, Ulaan Bataar" },
  { value: "Asia/Seoul", label: "Seoul" },
  { value: "Asia/Tokyo", label: "Osaka, Sapporo, Tokyo" },
  { value: "Asia/Yakutsk", label: "Yakutsk" },
  { value: "Australia/Darwin", label: "Darwin" },
  { value: "Australia/Adelaide", label: "Adelaide" },
  { value: "Australia/Sydney", label: "Canberra, Melbourne, Sydney" },
  { value: "Australia/Brisbane", label: "Brisbane" },
  { value: "Australia/Hobart", label: "Hobart" },
  { value: "Asia/Vladivostok", label: "Vladivostok" },
  { value: "Pacific/Guam", label: "Guam, Port Moresby" },
  { value: "Asia/Magadan", label: "Magadan, Solomon Is., New Caledonia" },
  { value: "Pacific/Fiji", label: "Fiji, Kamchatka, Marshall Is." },
  { value: "Pacific/Auckland", label: "Auckland, Wellington" },
  { value: "Pacific/Tongatapu", label: "Nuku'alofa" },
];
