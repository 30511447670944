export interface RoleOption {
  value: string
  label: string
  priority: number
}

export interface ServiceDetails {
  label: string
  name: string
  option: RoleOption[]
}

export interface Roles {
  [key: string]: string | undefined
}

export enum UserRoleType {
  SuperUser = "Super User",
  SuperUserReadOnly = "Super User Read Only",
  Reseller = "Reseller",
  ResellerReadOnly = "Reseller Read Only",
  OfficeManager = "Office Manager",
  OfficeManagerReadOnly = "Office Manager Read Only",
  SiteManager = "Site Manager",
  SiteManagerReadOnly = "Site Manager Read Only",
}
