import AlertIcon from "../Icons/AlertIcon"
import Customer from "../Icons/CustomerIcon"
import Numbers from "../Icons/NumbersIcon"
import ContactBookIcon from "../Icons/ContactBookIcon"
import DashboardIcon from "../Icons/Dashboard"
import FaxIcon from "../Icons/Fax"
import Partner from "../Icons/Partner"
import TeamsIcon from "../Icons/TeamsIcon"
import TrainingIcon from "../Icons/TrainingIcon"

/* ============================== MENU ITEMS ============================== */

interface MenuListTypes {
  id: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any
  title: string
  url: string
  target?: string
}

// eslint-disable-next-line import/prefer-default-export
export const MenuList: MenuListTypes[] = [
  {
    id: "dashboard",
    icon: () => <DashboardIcon />,
    title: "Home",
    url: "/dashboard"
  },
  {
    id: "users",
    icon: () => <Customer />,
    title: "Users",
    url: "/users"
  },
  {
    id: "customer",
    icon: () => <Customer />,
    title: "Customers",
    url: "/customer/reseller"
  },
  {
    id: "porting",
    icon: () => <Numbers />,
    title: "Numbers",
    url: "/numbers"
  },
  {
    id: "securefax",
    icon: () => <FaxIcon />,
    title: "SecureFax",
    url: "/secure-fax"
  },
  {
    id: "partner",
    icon: () => <Partner />,
    title: "Partner",
    url: "/partner/deals-pipeline"
  },
  {
    id: "lms",
    icon: () => <TrainingIcon />,
    title: "Training",
    url: "https://onecloud.com/resources/videos/",
    target: "_blank"
  },
  {
    id: "teams",
    icon: () => <TeamsIcon />,
    title: "Teams",
    url: "/teams"
  },
  {
    id: "ucaas",
    icon: () => <ContactBookIcon />,
    title: "UCaaS",
    url: "/ucaas"
  },
  {
    id: "alertBlast",
    icon: () => <AlertIcon />,
    title: "AlertBlast",
    url: "https://myalertblast.com/admin",
    target: "_blank"
  }
]
